import React from 'react';
import './_index.scss';
import { Box, Grid, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import CallIcon from '@mui/icons-material/Call';
import ContactMailIcon from '@mui/icons-material/ContactMail';

export const Footer = () => {
    return (
        <Box component='footer' className='global-footer'>
            <Grid container spacing={7}>
                <Grid container item lg={12} sx={{ display: 'flex', justifyContent: 'center' }} spacing={5}>
                    <Grid item>
                        <FacebookIcon fontSize='large' />
                    </Grid>

                    <Grid item>
                        <a href="https://www.instagram.com/karukera.evasions" target='_blank'>
                            <InstagramIcon fontSize='large' />
                        </a>
                    </Grid>

                    <Grid item>
                        <a href="tel:+590690595376">
                            <CallIcon fontSize='large' />

                            <Typography>+590 (0)6 90 59 53 76</Typography>
                        </a>
                    </Grid>

                    <Grid item>
                        <a href="mailto:contact@karukera-evasions.fr">
                            <ContactMailIcon fontSize='large' />
                                
                            <Typography>contact@karukera-evasions.fr</Typography>
                        </a>
                    </Grid>
                </Grid>

                <Grid item lg={12} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Typography>Karukera Evasions - Copyright © {new Date().getFullYear()}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};
