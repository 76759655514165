import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'routes/Router';
import 'config/i18n';
import 'assets/styles/_global.scss';

function App() {
    return (
        <HelmetProvider context={{}}>
            <Router />
        </HelmetProvider>
    );
}

export default App;
