import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from 'translations/fr.json';
import en from 'translations/en.json';

const resources = {
    fr,
    en 
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: ['fr'],
        detection: {
            order: ['queryString', 'cookie'],
            caches: ['cookie']
        }
    });
