import React from 'react';
import './_index.scss';
import { Fab, Typography } from '@mui/material';
import ancreIcon from '../../assets/images/icon-ancre-white.png';
import { useTranslation } from 'react-i18next';

export const BookNow = () => {
    const { t } = useTranslation('shared');

    return (
        <Fab className='book-button' variant="extended">
            <img src={ancreIcon} width={35} alt='ancre icon' style={{ marginRight: '0.5em' }} />
            
            <Typography color='#fff'>
                {t('book-now')}
            </Typography>
        </Fab>
    );
};
