import React from 'react';
import './_index.scss';
import { Outlet } from 'react-router-dom';
import { BookNow } from 'shared/book-now/BookNow';

export const GlobalLayout = () => {
    return (
        <div className='global-layout'>
            <Outlet />

            <BookNow />
        </div>
    );
};
