import React, { Fragment } from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';
import { routes } from './routes';
import { GlobalLayout } from 'layouts/global/GlobalLayout';
import { DrawerNavbar } from 'shared/drawer-navbar/DrawerNavbar';
import { Footer } from 'shared/footer/Footer';

const HomePage = React.lazy(() => import('../pages/home/HomePage'));
const WelcomeAboardPage = React.lazy(() => import('../pages/welcome-aboard/WelcomeAboardPage'));
const OurAventuresPage = React.lazy(() => import('../pages/our-aventures/OurAventuresPage'));
const OurPartnersPage = React.lazy(() => import('../pages/our-partners/OurPartnersPage'));
const NoMatch404Page = React.lazy(() => import('../pages/no-match-404/NoMatch404Page'));

export const Router = () => {
    return (
        <Fragment>
            <BrowserRouter>
                <DrawerNavbar />

                <React.Suspense fallback={<div>Chargement...</div>}>
                    <Switch>
                        <Route element={<GlobalLayout />}>
                            <Route path={routes.home} element={<HomePage />} />
                            <Route path={routes.welcomeAboard} element={<WelcomeAboardPage />} />
                            <Route path={routes.ourAventures} element={<OurAventuresPage />} />
                            <Route path={routes.ourPartners} element={<OurPartnersPage />} />

                            { /* ------------------- 404 Page Not Found ------------------- */ }
                            <Route path="*" element={<NoMatch404Page />} />
                        </Route>
                    </Switch>
                </React.Suspense>

                <Footer />
            </BrowserRouter>
        </Fragment>
    );
};
