import React, { useState } from 'react';
import './_index.scss';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Image from 'mui-image';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';
import logoPNG from '../../assets/images/logo/Karukera_Evasions_Logo.png';

interface Props {
    /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
    window?: () => Window;
}

export const DrawerNavbar: React.FC<Props> = ({ window }) => {
    const { t } = useTranslation('page');
    const [mobileOpen, setMobileOpen] = useState(false);
    const container = window !== undefined ? () => window().document.body : undefined;

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    return (
        <Box>
            <AppBar component="nav" className='global-appbar'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box className='global-appbar-menu' sx={{ display: { xs: 'none', sm: 'flex', justifyContent: 'center' } }}>
                        <Stack direction='row' spacing={8} alignItems='center'>
                            <Link to={routes.home}>
                                {t('home')}
                            </Link>

                            <Link to={routes.welcomeAboard}>
                                {t('welcome-aboard')}
                            </Link>

                            <Link to={routes.ourAventures}>
                                {t('our-aventures')}
                            </Link>

                            <Link to={'#'}>
                                {t('embark')}
                            </Link>

                            <Link to={routes.ourPartners}>
                                {t('our-partners')}
                            </Link>
                        </Stack>
                    </Box>
                </Toolbar>
            </AppBar>

            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                    sx={{ display: { xs: 'block', sm: 'none' } }}
                    className='drawer-appbar'
                >
                    <Box className='drawer-appbar-box' onClick={handleDrawerToggle}>
                        <Typography variant="h6" sx={{ my: 2 }}>
                            <Image
                                src={logoPNG}
                                fit='contain'
                                duration={1500}
                                easing='ease'
                                bgColor="#fff"
                            />
                        </Typography>

                        <Divider />

                        <List className='drawer-appbar-box-menu'>
                            <Stack direction='column' spacing={6}>
                                <Link to={routes.home}>
                                    {t('home')}
                                </Link>

                                <Link to={routes.welcomeAboard}>
                                    {t('welcome-aboard')}
                                </Link>

                                <Link to={routes.ourAventures}>
                                    {t('our-aventures')}
                                </Link>

                                <Link to={'#'}>
                                    {t('embark')}
                                </Link>

                                <Link to={routes.ourPartners}>
                                    {t('our-partners')}
                                </Link>
                            </Stack>
                        </List>
                    </Box>
                </Drawer>
            </Box>
        </Box>
    );
};
